// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-enquiries-js": () => import("./../../../src/pages/enquiries.js" /* webpackChunkName: "component---src-pages-enquiries-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-limited-edition-prints-js": () => import("./../../../src/pages/limited-edition-prints.js" /* webpackChunkName: "component---src-pages-limited-edition-prints-js" */),
  "component---src-pages-paypal-cancel-js": () => import("./../../../src/pages/paypal-cancel.js" /* webpackChunkName: "component---src-pages-paypal-cancel-js" */),
  "component---src-pages-paypal-success-js": () => import("./../../../src/pages/paypal-success.js" /* webpackChunkName: "component---src-pages-paypal-success-js" */),
  "component---src-pages-public-collections-js": () => import("./../../../src/pages/public-collections.js" /* webpackChunkName: "component---src-pages-public-collections-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-ra-solo-show-js": () => import("./../../../src/pages/ra-solo-show.js" /* webpackChunkName: "component---src-pages-ra-solo-show-js" */),
  "component---src-pages-sketchbooks-js": () => import("./../../../src/pages/sketchbooks.js" /* webpackChunkName: "component---src-pages-sketchbooks-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-watercolours-js": () => import("./../../../src/pages/watercolours.js" /* webpackChunkName: "component---src-pages-watercolours-js" */),
  "component---src-pages-work-1950-1980-js": () => import("./../../../src/pages/work-1950-1980.js" /* webpackChunkName: "component---src-pages-work-1950-1980-js" */),
  "component---src-pages-work-1980-2000-js": () => import("./../../../src/pages/work-1980-2000.js" /* webpackChunkName: "component---src-pages-work-1980-2000-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

